<template>
   <div id="inside">
    <div class="container" v-if="!loadOffers && contracts">
      <div
          class="
            float-right
            col-12
            flex-column
            px-2
            pb-4
            d-flex
            order-1 order-lg-3
            justify-content-center
            align-items-center
            col-lg-4
          "
          style=""
        >
          <div class="btn-group btn-group-sm">
            <button
              class="
                btn btn-link
                d-flex
                flex-column
                justify-content-left
                text-decoration-none
              "
              @click="contractsPending()"
            >
              <i class="fa fa-edit fa-fw"></i> contratos
            </button>
            <button
              class="
                btn btn-link
                d-flex
                flex-column
                justify-content-left
                text-decoration-none
              "
              @click="verBoletos()"
            >
              <i class="fa fa-fw fa-file-text-o"></i> meus acordos
            </button>
              <button
                class="
                  btn btn-link
                  d-flex
                  flex-column
                  justify-content-left
                  text-decoration-none
                "
                @click="cadastro()"
                ><i class="fa fa-fw fa-user-o"></i>cadastro</button
              ><button
                class="
                  btn btn-link
                  d-flex
                  flex-column
                  justify-content-left
                  text-decoration-none
                "
                @click="cadastro()"
                ><i class="fa fa-fw fa-key"></i>código de acesso</button
              >
            <!-- <router-link
              to="https://api.whatsapp.com/send?l=pt&amp;phone=5514000000000"
              class="nav-item"
              style="color: #0da0db; text-decoration: none"
              ><a
                class="
                  btn btn-link
                  d-flex
                  flex-column
                  justify-content-left
                  text-decoration-none
                "
                target="_blank"
                ><i class="fa fa-fw fa-whatsapp"></i>whatsapp</a
              ></router-link
            > -->
          </div>
        </div>
      <div class="row" v-if="this.contractsSelected.OwnerID != 34">
        <div class="col-12 order-1 order-lg-2 col-lg-12 mt-2" style="">
          <h4 class="mb-1 text-primary">Simulador</h4>
          <h6 class="mt-3 mb-1">Contrato {{ contracts.NumeroTitulo }}</h6>
          <!-- <p class="text-info mb-1">{{ contracts.Produto }}</p> -->
          <p class="mb-1 mt-3">Total devido:</p>
          <!-- <h5 class="text-danger">{{ sumByContract() }}</h5> -->
        </div>
      </div>
      <div class="row">
        <div class="col-12 order-1 order-lg-2 col-lg-12 mt-2" style="margin-bottom: 20px;">
          <button class="btn btn-primary mt-3" @click="voltar()">Voltar</button>
        </div>
      </div>
    </div>
    <div class="container" v-if="loadOffers">
      <ProgressLoading v-bind:loading="loadOffers" :style="largura < 500 ? 'margin: auto;' : ''"/>
    </div>
    <div class="container" v-if="!loadOffers">
      <div class="row">
        <div class="col-md-12">
          <h5 class="mt-3">Selecione uma proposta:</h5>
        </div>
      </div>
      <div class="row" style="" >
        <!-- parcelado-->
        <div class="container-fluid d-flex flex-wrap">
          <div v-bind:class="[ offer.Plano === '1' ? 'card p-3 mr-5 col-lg-3' : 'card p-3 mr-4 col-lg-2' ]" 
          :style="largura < 500 ? 'margin: 15px auto !important;' : ''"
          v-for="(offer, key, index) in offers" :key="`${key}-${index}`">
            <div class="card-header" style="">
              <div class="row">
                <div class="col-12">
                  <!-- p = ( x / y ) * 100  -->
                  <h5 v-if="offer.Plano === '1'" class="text-success">À vista &nbsp;
                    <h6 class="badge badge-pill badge-success" style="font-size:13px;">{{ Math.floor((parseFloat(offer.ValorDesconto, 10) / parseFloat(offer.ValorCorrigido, 10) * 100)).toFixed(0) }}% de desconto</h6>
                  </h5>
                  <h6 v-if="offer.Plano != '1'">Proposta {{ offer.Plano }}&nbsp;
                    <h6 class="badge badge-pill badge-success" style="font-size:13px;">{{ Math.floor((parseFloat(offer.ValorDesconto, 10) / parseFloat(offer.ValorCorrigido, 10) * 100)).toFixed(0) }}% de desconto</h6>
                  </h6>
                </div>
              </div>
            </div>
            <div class="card-body pb-2 pt-0">
              <!-- <p class="mb-0 text-info" v-if="offer.Plano != '1'">Entrada de: <span class="text-muted"> R$ {{ parseFloat(offer.ValorPrimeira.replace(',', '.')).toLocaleString('pt-BR', { currency: 'BRL' , minimumFractionDigits: 2}) }} </span></p> -->
              <p class="mb-0 text-info" v-if="offer.Plano === '1'">Pagamento único de: <span class="text-muted"> R$ {{  parseFloat(offer.ValorPrimeira.replace(',', '.')).toLocaleString('pt-BR', { currency: 'BRL' , minimumFractionDigits: 2}) }} </span></p>
              <!-- <h6 class="text-muted">R$ {{ offer.ValorPrimeira }}&nbsp;&nbsp;</h6> -->
              <div class="row border-bottom py-4">
                <div class="col-lg-6 col-7">
                  <h6 class="mb-1">Nº de parcelas: 
                     <h6 class="text-success" v-if="offer.Plano === '1'">à vista</h6>
                      <h5 class="text-success" v-if="offer.Plano != '1'">{{ offer.Plano }}</h5> 
                  </h6>
                  <h6 class="mb-1" v-if="offer.Plano != '1'">Valor parcelas: <br> R$ {{ parseFloat(offer.ValorDemaisParcelas.replace(',', '.'), 10).toLocaleString('pt-BR', { currency: 'BRL' , minimumFractionDigits: 2}) }}</h6>
                  
                  
                </div>
                <div class="col-lg-6 mb-3 col-5">
                  <h6 class="mb-1">Total com desconto:</h6>
                  <h6 class="text-primary" v-if="offer.ValorDemaisParcelas === '0'">R$ {{ parseFloat(offer.ValorNegociar.replace(',', '.'), 10).toLocaleString('pt-BR', { currency: 'BRL' , minimumFractionDigits: 2}) }}</h6>
                  <h6 class="text-primary" v-if="offer.ValorDemaisParcelas != '0'">R$ {{ parseFloat(offer.ValorNegociar.replace(',', '.'), 10).toLocaleString('pt-BR', { currency: 'BRL' , minimumFractionDigits: 2}) }}</h6>
                  <h6 class="mb-1 text-info small">Vencimento dia {{ offer.VencimentoPrimeira.slice(0, 2) }}</h6>
                </div>
              </div>
              <div class="row"> 
                <div class="col-md-12 d-flex justify-content-center">
                  <button class="btn btn-primary mt-3" v-if="!loadSetAgreement" @click="fecharAcordo(offer)">Fechar este acordo</button>
                </div>
              </div>
            </div>
          </div>

          <!-- cetelem offers-->

          <div v-bind:class="[ offer.installmentsNumber === '0' ? 'card p-3 mr-5 col-lg-3' : 'card p-3 mr-4 col-lg-2' ]" 
          :style="largura < 500 ? 'margin: 15px auto !important;' : ''"
          v-for="(offer, key, index) in cardCetelem" :key="`${key}-${index}`">
            <div class="card-header" style="">
              <div class="row">
                <div class="col-12">
                  <!-- p = ( x / y ) * 100  -->
                  <h5 v-if="offer.installmentsNumber === 0" class="text-success">À vista &nbsp;
                    <!-- <h6 class="badge badge-pill badge-success" style="font-size:13px;">{{ offer.inputValue }}</h6> -->
                  </h5>
                  <h6 v-if="offer.installmentsNumber != 0">Proposta {{ key }}&nbsp;
                    <!-- <h6 class="badge badge-pill badge-success" style="font-size:13px;">{{ offer.installmentsValue}}</h6> -->
                  </h6>
                </div>
              </div>
            </div>
            <div class="card-body pb-2 pt-0">
              <!-- <p class="mb-0 text-info" v-if="offer.Plano != '1'">Entrada de: <span class="text-muted"> R$ {{ parseFloat(offer.ValorPrimeira.replace(',', '.')).toLocaleString('pt-BR', { currency: 'BRL' , minimumFractionDigits: 2}) }} </span></p> -->
              <!-- <h6 class="text-muted">R$ {{ offer.ValorPrimeira }}&nbsp;&nbsp;</h6> -->
              <div class="row border-bottom py-4">
                <div class="col-lg-6 col-7">
                  <h6 class="mb-1">Nº de parcelas: 
                     <h6 class="text-success" v-if="offer.installmentsNumber === 0">à vista</h6>
                      <h5 class="text-success" v-if="offer.installmentsNumber != 0">{{ offer.installmentsNumber }}</h5> 
                  </h6>
                </div>
                <h6 class="mb-1" v-if="offer.installmentsNumber === 0">Pagamento <br>único de: <br>R$ {{ offer.inputValue }} </h6>
                <h6 class="mb-1" v-if="offer.installmentsNumber != '0'">Valor parcelas: <br> R$ {{ offer.installmentsValue }}</h6>

                <!-- <div class="col-lg-6 mb-3 col-5">
                  <h6 class="mb-1">Total com desconto:</h6>
                  <h6 class="text-primary" v-if="offer.installmentsValue === 0">R$ {{ offer.installmentsValue }}</h6>
                  <h6 class="text-primary" v-if="offer.installmentsValue != 0">R$ {{ offer.installmentsValue }}</h6>
                  <h6 class="mb-1 text-info small">Vencimento dia {{ offer.dueDate.slice(8, 10) }}</h6>
                </div> -->
              </div>
              <div class="row"> 
                <div class="col-md-12 d-flex justify-content-center">
                  <button class="btn btn-primary mt-3" v-if="!loadSetAgreement" @click="fecharAcordo(offer)">Fechar este acordo</button>
                </div>
              </div>
            </div>
          </div>

    <div class="container" v-if="!loadOffers">
      <div class="row border-top border-bottom my-3 mx-auto border-info py-4">
        <div class="col-lg-4 my-3">
          <h5 class="mt-3">Selecionar outras opções de negociação:</h5>
        </div>
        <div class="col-lg-4 mt-3">
          <div class="form-group">
            <div class="form-group" v-if="optionsCetelem && !loadOffers" >
              <select id="ParcelasSelect" class="form-control mt-2" @change="getValue($event.target.value)">
                <option v-for="(value, key, index) in optionsCetelem" :key="`${key}-${index}`" :value="JSON.stringify(value)">{{ value.installmentsNumber}}x {{ value.installmentsValue }}</option>
                <option v-if="optionsCetelem.length < 1">Não existem mais opções disponíveis</option>
              </select>
            </div>
            <!-- <div class="form-group" v-if="offersCetelem && !loadOffers" >
              <select id="ParcelasSelectPst" class="form-control mt-2" @change="getValue($event.target.value)">
                <option v-for="(value, key, index) in offersCetelem.offers" :key="`${key}-${index}`" :value="JSON.stringify(value)">{{ value.installmentsNumber }}x {{ value.installmentValue }}</option>
                <option v-if="!newOffersLoaded">Carregar mais opções</option>
                <option v-if="negociarPagamentoTotal && newOffersLoaded && !othersOptionsLoaded">Outras opções de negociação</option>
                <option v-if="offersCetelem.offers.length < 1">Não existem mais opções disponíveis</option>
              </select>
            </div> -->

          </div>
        </div>
        <div class="my-3 text-center col-lg-2">
          <button class="btn mr-1 mt-2 btn-primary" :disabled="loadOffers || offersCetelem.offers.length < 1" @click="fecharAcordo(null)">Fechar este acordo</button>
        </div>
      </div>
    </div>

          
        </div>
        <ProgressLoading v-bind:loading="loadSetAgreement" />
      </div>
      <div class="row" style="">
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { formatCurrencieValueBRL as formatValue, formatData } from "@/utils/helpers";
import ProgressLoading from "../../../modules/auth/components/ProgressLoading.vue";

export default {
    name: "HistoricoPagamentos",
     components: {
      ProgressLoading
    },
    data () {
        return {
            loadOffers: false,
            negociacao: null,
            contract :null,
            selectDeal:null,
            digitalLine:null,
            offers: [],
            offersCetelem: [],
            optionsCetelem: [],
            cardCetelem: [],
            checkSelectAll: false,
            checkContract: false,
            links: [],
            largura: null,
            loadSetAgreement: false,
            userAdm: {},
            ipUser: null
        }
    },
    methods: {
        formatValue,
        formatData,

        ...mapActions("meusContratos", ["ActionGetOffersClaro", "ActionSetAgreement"]),

        sumByContract() {
          if(this.offers) {
            this.offers = Array.isArray(this.offers) ? this.offers : [this.offers]
            return parseFloat(this.offers[0].ValorOriginal.replace(".", "").replace(",", ".")).toLocaleString("pt-BR", { style: "currency", currency: "BRL" }).replace("-", "");
          }
        },


        deactivated() {
            this.divida = null;
            this.negociacao = null;
            this.selectDeal = null;
            this.digitalLine = null;
        },
        voltar() {
          this.$router.push({ name: "dashboard" });
        },
        voltarNegociar() {
             this.$router.push({ 
               name: 'negociar',
               params: { contracts: this.contractFull },
              });
        },
        showDigitalLine(){
          this.digitalLine = this.selectDeal.linhaDigitavel;
        },

        selectAll() {
              var marcar = document.getElementById('all').checked;
              var lista = document.getElementsByClassName("form-check-input check1 bg-primary");
              for (var i = 0; i < lista.length; i++) {
                lista[i].checked = marcar;
              }
        },

        select() {
          var lista = document.getElementsByClassName("form-check-input check1 bg-primary");
          var all = document.getElementById('all');
          if(lista.length <= 2) {
            all.checked = false;
          }
        },

        clear() {
              var lista = document.getElementsByClassName("form-check-input check1 bg-primary");
              for (var i = 0; i < lista.length; i++) {
                lista[i].checked = false;
              }
        },


         async fecharAcordo(offer) {
          
          this.loadSetAgreement = true;
          localStorage.removeItem('contracts');
          localStorage.removeItem('contract');
          try {
            var response = null

        if(this.contractsSelected.OwnerID == 34){
              response = await this.ActionSetAgreement({
              dossieNumber: this.contractsSelected.contractNumber,
              agreementType: this.offersCetelem.agreementType,
              contractValue: this.contractsSelected.updatedValue,
              inputValue: offer.inputValue,
              dueDate: offer.dueDate,
              installmentsNumber: offer.installmentsNumber,
              installmentsValue: offer.installmentsValue,
              OwnerID: this.contractsSelected.OwnerID,
              UserID: this.userAdm ? this.userAdm.UserID : null,
              UserFullName: this.userAdm ? this.userAdm.UserFullName : null,
              OS: navigator.platform,
              IP: this.ipUser ? this.ipUser : '',
        });
        
        }else{
              response = await this.ActionSetAgreement({  
              key: this.contractsSelected.Provider,
              debtorCode: this.contractsSelected.CodigoDevedor,
              titleCode: this.contractsSelected.CodigoTitulo,
              NomeDevedor: this.contractsSelected.NomeDevedor,
              plan: offer.Plano,
              dueDate: offer.VencimentoPrimeira,
              value: offer.ValorPrimeira,
              info : this.contracts.info,
              httpOwnerId: this.contractsSelected.OwnerID,
              UserID: this.userAdm ? this.userAdm.UserID : null,
              UserFullName: this.userAdm ? this.userAdm.UserFullName : null,
              OS: navigator.platform,
              IP: this.ipUser ? this.ipUser : '',
            });
        }

        console.log(response)
            

            if(response) {
              this.flashMessage.show({
                timeout: 1000,
                status: "success",
                title: "Sucesso",
                blockClass: "custom-block-class",
                message: "Acordo fechado com sucesso",
              });

            if(this.contractsSelected.OwnerID == 34){

              const prepareResponse = {
                ...this.contractsSelected,
                installments: this.offersCetelem.offers
                
              }

              console.log(prepareResponse)
              
              localStorage.setItem('contractClosed', JSON.stringify([prepareResponse]))
              localStorage.setItem('boletoSelected', JSON.stringify([prepareResponse]))
              this.$router.push({ 
                name: 'boletoAcordo'
              });

            }else{
              const prepareResponse = {
                ...response,
                Provider: this.contractsSelected.Provider,
                NomeDevedor: this.contractsSelected.NomeDevedor,
                NumeroTitulo: this.contractsSelected.NumeroTitulo
              }
              localStorage.setItem('contractClosed', JSON.stringify([prepareResponse]))
              localStorage.setItem('boletoSelected', JSON.stringify([prepareResponse]))
              this.$router.push({ 
                name: 'boletoAcordo'
              });
            
              }

            } else {
              this.flashMessage.show({
                timeout:300,
                status: 'error',
                position:'top right', 
                title: 'Erro', 
                message: 'Não foi possível fechar o acordo, tente novamente mais tarde!'});
              this.loadSetAgreement = false;
            }
            this.loadSetAgreement = false;
          } catch (error) {
            this.flashMessage.show({
              timeout:300,
              status: 'error',
              position:'top right', 
              title: 'Erro', 
              message: 'Não foi possível fechar o acordo, tente novamente mais tarde!'});
            this.loadSetAgreement = false;
          }
        },

        verBoletos() {
          this.$router.push({
            name: "meusBoletos"
          });
        },
        
        contractsPending() {
          if(this.contracts.debts) {
            this.$router.push({
              name: "meusContratos"
            });
          }
        },

        cadastro() {
          this.$router.push({
            name: "cadastro",
            params: { agreements: this.contracts.agreements, contracts: this.contracts }
          });
        },
    },
    async created() {
      this.userAdm = JSON.parse(localStorage.getItem("userAdm"));
      this.ipUser = localStorage.getItem('ipUser');
      this.largura = window.screen.width;
      this.loadOffers = true;
      this.contracts = JSON.parse(localStorage.getItem("contracts"))
      this.contractsSelected = JSON.parse(localStorage.getItem("contractSelected"))

      try {
        if(this.contractsSelected.OwnerID == 34){
          this.offersCetelem = await this.ActionGetOffersClaro({
          dossieNumber: this.contractsSelected.contractNumber,
          contractValue: this.contractsSelected.updatedValue,
          daysDelay: 130,
          // daysDelay: this.contractsSelected.daysDelay,
          OwnerID: this.contractsSelected.OwnerID,
          UserID: this.userAdm ? this.userAdm.UserID : null,
          UserFullName: this.userAdm ? this.userAdm.UserFullName : null,
          OS: navigator.platform,
          IP: this.ipUser ? this.ipUser : '',
        });
        
        }else{
        this.offers = await this.ActionGetOffersClaro({
          offerId: this.contractsSelected.CodigoTitulo,
          key: this.contractsSelected.Provider,
          httpOwnerId: this.contractsSelected.OwnerID,
          UserID: this.userAdm ? this.userAdm.UserID : null,
          UserFullName: this.userAdm ? this.userAdm.UserFullName : null,
          OS: navigator.platform,
          IP: this.ipUser ? this.ipUser : '',
        });
      }

      this.offersCetelem.offers.map((item) => {
            const installments = [0, 3, 6, 12, 24, 36, 48, 60];
            if (installments.indexOf(item.installmentsNumber) !== -1) {
              this.cardCetelem.push(item)
            }else{
              this.optionsCetelem.push(item)
            }
      });

      console.log(this.cardCetelem)

        if(this.offers.length === 0 && this.offersCetelem.length === 0) throw "Error"
        this.loadOffers =  false;
      } catch (error) {
        this.flashMessage.show({
          timeout:300,
          status: 'error',
          position:'top right', 
          title: 'Erro', 
          message: 'Não foi possível buscar ofertas, tente novamente mais tarde!'
        });
        this.loadOffers =  false;
        this.$router.push({
          name: "dashboard",
        });
      }
      this.loadOffers = false;
    },
    computed: {
      ...mapState("auth", ["user"]),
      isLoggedIn() {
      return this.$store.getters["auth/isLoggedIn"];
    },
    }
};
</script>

<style scoped>

@media(max-width: 500px) {
    .card {
        margin-right: 0;
    }
}


</style>
